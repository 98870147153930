import React, { useState, useContext, useEffect } from 'react';
import { WhatsappShareButton, WhatsappIcon } from 'react-share';
import './App.css';
import Button from 'react-bootstrap/Button';

export const ProgressBar = ({questions}) => {

    const q = questions[0];

    const dateToday = () => {
        const today = new Date(q.serverDate);
        const month = today.toLocaleString('default', { month: 'long' });
        const day = today.getDate()
        const dow = today.toLocaleString('default', { weekday: 'long' });
        let daystr;
        switch(day) {
            case 1:
            case 21:
            case 31:
                daystr=`${day}st`;
                break;
            case 22:
            case 2: daystr=`${day}nd`;
                break;
            case 23:
            case 3: daystr=`${day}rd`;
                break;
            default:daystr=`${day}th`;
                break;
        }
        const year = today.getFullYear()
        return `${dow}, ${daystr} ${month} ${year}`
    }

    const width = `100%`

    return (
        <>
            <h1 className="text-center">Weekly Quiz for Medical Students</h1>
            
            <p className="text-center date">{dateToday()}</p>
            <p className="text-center"></p>
            <div className="progress mb-4">
                <div className="progress-bar" role="progressbar" style={{width: width}} aria-valuenow="75" aria-valuemin="0" aria-valuemax="100">
                </div>
            </div>
        </>
    )
}


export const Subject = ({questions, text}) => {
    const q = questions[0]
    const subject = (text === undefined) ? q.topic || "General" : text
    return (
        <div className="d-flex justify-content-between align-items-center mb-3">
            <h2>{subject}</h2>
        </div>
    )
}

export const Header = () => {
    return (
        <div className="container">
            <a className="navbar-brand" href="#">DocQuiz</a>
            <button className="navbar-toggler p-0 border-0" type="button" data-bs-toggle="offcanvas"
                    data-bs-target="#offcanvasExample" aria-controls="offcanvasExample">
                <span className="navbar-toggler-icon"></span>
            </button>
            <div className="navbar-collapse offcanvas-collapse" id="navbarsExampleDefault">
                <ul className="navbar-nav ms-auto">
                    <li className="nav-item">
                        <a className="nav-link" href="#"></a>
                    </li>
                </ul>
            </div>
        </div>
    )
}

export const ShareButton = () => {
    return (
        <WhatsappShareButton
            url={'https://www.docquiz.in/'}
            title={'Try todays medical quiz at: '}
            hashtag={'#medicalquiz'}>
            <WhatsappIcon size={40} round={true} />
        </WhatsappShareButton>
    )
}

export const ReadMore = ({children}) => {
    const text = children
    const [readmore, setReadmore] = useState(true)

    const toggleReadmore = () => {
        setReadmore(!readmore)
    }

    return (
            <p>
                {readmore ? text.slice(0,100)+(text.length > 100 ? "..." : ""): text}
                {(text.length > 100) &&
                    (<span style={{marginLeft:"1rem", cursor:"pointer"}}onClick={toggleReadmore}><i>{readmore ? "[ show more ]" : "[ show less ]"}</i></span>)}

            </p>
    )
}

export const ColoredLine = ({ color }) => (
    <hr
        style={{
            color: color,
            backgroundColor: color,
            height: 5,
            marginBottom: "3rem"
        }}
    />
);

/*
 <h3 className="text-center">Testing knowledge and practice of Internal Medicine</h3>
 */