import React from 'react';
import ReactDOM from 'react-dom/client';
import './index.css';
import App from './App';
import Admin from './Admin';
import AddQuestion from './AddQuestion';
import SetQuestion from './SetQuestion';
import ShowQuestions from './ShowQuestions';
import SeeResponses from './SeeResponses';
import 'react-calendar/dist/Calendar.css';

import { BrowserRouter, Route, Routes } from 'react-router-dom';

import reportWebVitals from './reportWebVitals';

const root = ReactDOM.createRoot(document.getElementById('root'));
root.render(
  <React.StrictMode>
      <BrowserRouter>
          <Routes>
              <Route path="/admin" element={<Admin/>} />
              <Route path="/admin/add-question" element={<AddQuestion/>} />
              <Route path="/admin/set-question" element={<SetQuestion/>} />
              <Route path="/admin/list-questions" element={<ShowQuestions/>} />
              <Route path="/admin/see-responses" element={<SeeResponses/>} />
              <Route path="*" element={<App/>} />
          </Routes>
      </BrowserRouter>
  </React.StrictMode>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
