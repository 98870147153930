import React, { useState } from 'react';
import { Link } from 'react-router-dom';
import Button from 'react-bootstrap/Button';

const Admin = () => {
    return (
        <>
        <Link to='/admin/add-question'>
            <div>
                <Button>Add Question</Button>
            </div>
        </Link>
        <Link to='/admin/set-question'>
            <div className="vertical-spacer">
                <Button>Set Question of the Day</Button>
            </div>
        </Link>
        <Link to='/admin/list-questions'>
            <div className="vertical-spacer">
                <Button>List All Questions</Button>
            </div>
        </Link>
        <Link to='/'>
            <div className="vertical-spacer">
                <Button>User Screen</Button>
            </div>
        </Link>
        <Link to='/admin/see-responses'>
            <div className="vertical-spacer">
                <Button>See Responses</Button>
            </div>
        </Link>
        </>
    )
}

export default Admin