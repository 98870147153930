import React, { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import './App.css';
import Button from 'react-bootstrap/Button';
import {FirstPage} from './FirstPage'
//import testdata from './t.json';


const AddQuestion = () => {
    const emptyQuestion = {
        subject:"",
        topic:"",
        feedback:"",
        question:"",
        choices: [{label:"A", value:""}],
        rightchoice:[],
        setter:"Velayudhan Menon",
        used:false,
        now:false,
        usedon:null,
        insertedon:Date()
    }
    const qBatchSize = 5
    const [allQuestions, setAllQuestions] = useState([emptyQuestion])
    //const [allQuestions, setAllQuestions] = useState(testdata)
    const [qIndex, setQIndex] = useState(0)
    const [status, setStatus] = useState({questionsAdded:false, failMessage:false})
    const [inPreview, setInPreview] = useState(false)

    const navigate = useNavigate()

    /*useEffect(() => {
        if (questions.length === 0) {
            fetch('/api/getquestions')
                .then(response => response.json())
                .then(json => setQuestions(json))
                .catch(error => console.error(error));
        };
    })*/

    const addAnotherChoice = (e) => {
        e.preventDefault()
        const questions = [...allQuestions]
        const q = questions[qIndex]
        const charCode = "A".charCodeAt(0) + q.choices.length
        q.choices.push({label:String.fromCharCode(charCode), value:""})
        setAllQuestions(questions)
    }

    const removeChoice = (e, q, i) => {
        e.preventDefault()
        const questions = [...allQuestions]

        console.log("before", JSON.stringify(questions))

        const label = String.fromCharCode("A".charCodeAt(0) + i)
        const index = questions[q].rightchoice.indexOf(label)
        if (index !== -1)
            questions[q].rightchoice.splice(index,1)

        if (i === 0) {
            questions[q].choices[0].value=""
        }
        else {
            questions[q].choices.splice(i,1)
        }

        console.log("after", JSON.stringify(questions))

        setAllQuestions(questions)
    }

    const displayChoices = () => {
        const display = []
        const questions = [...allQuestions]
        const q = questions[qIndex]

        for (let i=0; i<q.choices.length; i++) {
            const choice = q.choices[i];
            display.push(
                 <div key={choice.label}>
                    <div>
                        {
                            (i === q.choices.length -1) &&
                            <button className="link button-spacer" style={{background:"none",border:"none"}} onClick={(e) => {removeChoice(e,qIndex,i)}}>x</button>
                        }
                        <label style={{marginRight:"50px"}} htmlFor={choice}>Choice {choice.label}</label>
                        <input type="checkbox"
                               name={choice.label}
                               id={choice.label}
                               checked={q.rightchoice.includes(choice.label)}
                               onChange={(e)=>toggleRightChoice(e, choice.label)}
                               className="me-2 flex-shrink-0 custom-radio"/>
                        <label className="form-check-label">This is a correct choice</label>

                    </div>
                    <textarea rows="2" cols="80" value={choice.value} onChange={(e)=>{setChoiceValue(e,choice.label)}}></textarea>
                    <div style={{height:"10px"}}></div>
                 </div>
            )
        }
        return display
     }

    const updateTopic = (e) => {
        const questions = [...allQuestions]
        const q = questions[qIndex]
        q.topic = e.target.value
        setAllQuestions(questions)
    }

    const updateQuestion = (e) => {
        const questions = [...allQuestions]
        const q = questions[qIndex]
        q.question = e.target.value
        setAllQuestions(questions)
    }

    const updateFeedback = (e) => {
        const questions = [...allQuestions]
        const q = questions[qIndex]
        q.feedback = e.target.value
        setAllQuestions(questions)
    }

    const setChoiceValue = (e, label) => {
        const questions = [...allQuestions]
        const q = questions[qIndex]
        const choices = [...q.choices]
        for (let i=0; i<choices.length; i++) {
            if (choices[i].label === label) {
                choices[i].value = e.target.value
                break;
            }
        }
        q.choices = choices
        setAllQuestions(questions)
    }

    const toggleRightChoice = (e, label) => {
        const questions = [...allQuestions]
        const q = questions[qIndex]
        const index = q.rightchoice.indexOf(label)
        if (index !== -1)
            q.rightchoice.splice(index,1)
        else
            q.rightchoice.push(label)
        setAllQuestions(questions)
    }

    const validateQuestions = () => {
        let failmessage = []
        for (let i=0; i<allQuestions.length;i++) {
            const q = allQuestions[i]
            if (q.rightchoice.length === 0)
                failmessage.push(`Q${i+1}: No right choices have been set.`)
        }
        if (failmessage.length === 0)
            return 0
        else
            return failmessage.join('\n')

    }

    const submitQuestions = (e) => {
        e.preventDefault()
        //console.log(JSON.stringify(allQuestions))
        let validateResult = validateQuestions()
        if ( validateResult !== 0) {
            const s = {...status}
            s.failMessage = validateResult
            setStatus(s)
            return
        }
        fetch('/api/addquestions', {
            method:"post",
            headers: {'Content-Type':'application/json', 'Accept':'application.json'},
            body: JSON.stringify(allQuestions)
        })
            .then(response => response.json())
            .then((json) => {
                const s = {...status}
                if (json.id > 0) {
                    s.questionsAdded = true
                    setStatus(s)
                }
                else {
                    s.failMessage = "failed to upload questions to database"
                    setStatus(s)
                }

            })
            .catch(error => console.error(error));
    }

    const goToPreviousQuestion = () => {
        if (qIndex > 0)
            setQIndex(qIndex-1)
    }

    const goToNextQuestion = () => {
        const questions = [...allQuestions]
        const index = qIndex + 1
        if (questions[index] === undefined)
            questions[index] = emptyQuestion
        setQIndex(index)
        setAllQuestions(questions)
    }

    const returnToStart = () => {
        setQIndex(0)
        setStatus({failMessage:false, questionsAdded:false})
    }

    const setPreview = () => {
        setInPreview(true)
    }

    const resetPreview = () => {
        setInPreview(false)
    }

    /*const resetQuestion = () => {
        const questions = [...allQuestions]
        questions[qIndex] = emptyQuestion
        setAllQuestions(questions)
    }*/


    const q=allQuestions[qIndex]

    if (inPreview)
        return <FirstPage questions={allQuestions} page={{page:0}} setPage={resetPreview}></FirstPage>

    if (status.failMessage !== false) {
        return (
            <div style={{marginTop:"20px",marginLeft:"200px"}}>
                <pre>{status.failMessage}</pre>
                <Button onClick={returnToStart}>Back</Button>
            </div>
        )
    }

    if (status.questionsAdded) {
        return (
            <div style={{marginTop:"200px",marginLeft:"200px"}}>
                <p>"Questions have been successfully added!"</p>

                <Button onClick={() => {navigate("/admin")}}>Return to Admin</Button>
            </div>
        )
    }

    return (
        <form>
            <div style={{marginBottom:"20px"}}>
                <label style={{display:"block"}}><b>{`Q${qIndex+1}`}</b></label>
                <label style={{display:"block"}}>Topic</label>
                <input type="text" name="topic" id="topic" size="80" value={q.topic} onChange={(e)=>updateTopic(e)}></input>
            </div>
            <label>Question</label>
            <textarea name="question" id="question" value={q.question} onChange={(e)=>updateQuestion(e)} rows="5" cols="80"></textarea>
            <div style={{height:"20px"}}></div>
            <h3>Answer Choices</h3>
            {displayChoices()}
            <Button onClick={(e)=>{addAnotherChoice(e)}}>Add Choice</Button>
            <div style={{marginBottom:"20px"}}>
                <label style={{marginTop:"20px"}}>Feedback</label>
                <textarea name="feedback" id="feedback" value={q.feedback} onChange={(e)=>{updateFeedback(e)}} rows="5" cols="80"></textarea>
            </div>

            {(qIndex>0) && <Button className="button-spacer" onClick={goToPreviousQuestion}>Previous Question</Button>}
            <Button onClick={goToNextQuestion}>Next Question</Button>
            <div style={{marginTop:"5rem", marginLeft:"10rem"}}>
                <Button className="button-spacer" onClick={submitQuestions}>Done</Button>
                <Button onClick={setPreview}>Preview</Button>
            </div>
        </form>
    )
}


export default AddQuestion

/*
const showInfo = (message) => {
    setPopup({open:true, message})
}
 <CloseButton style={{fontSize:"0.75rem", lineHeight:"0px", marginLeft:"350px"}} onClick={removeChoice}></CloseButton>
const toggle = (e) => {
    //e.preventDefault()
    const t = {...toggles}
    if (t[e.target.name] !== null) {
        t[e.target.name] = !t[e.target.name]
        e.target.checked = t[e.target.name]
    }
    else
        t[e.target.name] = e.target.checked;
    setToggles(t)
}
 const [popup, setPopup] = useState({open:false, message:""})

 let x = Object.keys(toggles).reverse()[0]
 x = x.charCodeAt(0) + 1
 const choice = String.fromCharCode(x)
 const t = {...toggles}
 t[choice] = false
 setToggles(t)

    */
/*const genChoices = () => {
 const display = []
 const choices = Object.keys(toggles).sort()
 for (let i=0; i<choices.length; i++) {
 const choice = choices[i];
 display.push(
 <div key={choice}>
 <div>
 <label style={{marginRight:"50px"}} htmlFor={choice}>Choice {choice}</label>
 <input type="checkbox" name={choice} id={choice} onClick={toggle} className="me-2 flex-shrink-0 custom-radio"/>
 <label className="form-check-label">This is a correct choice</label>
 </div>
 <textarea name={choice} id={choice} rows="2" cols="80"></textarea>
 <div style={{height:"10px"}}></div>
 </div>
 )
 }
 return display
 }*/

/*
 <>
 {(popup.open)  &&
 <div>
 <h3>
 {popup.message}
 </h3>
 <button type="text" onClick={closePopup}>Add Another Question</button>
 <button type="text" onClick={() => {navigate("/admin")}}>Return to Admin</button>
 </div>
 }

 {(!popup.open) &&
 */

/*const submitQuestion = (e) => {
 e.preventDefault()
 const form = e.target.closest('form')
 const formData = new FormData(form)
 const formJson = Object.fromEntries(formData.entries())
 const q = {}
 q.question = formJson.question
 q.feedback = formJson.feedback
 q.topic = formJson.topic
 q.choices = []
 const choices = Object.keys(toggles).sort()
 for (let i=0; i<choices.length; i++) {
 const choice = {}
 choice.label = choices[i]
 choice.value = formJson[choices[i]]
 q.choices.push(choice)
 }
 q.rightchoice = []
 for (let i=0; i<choices.length; i++) {
 if (toggles[choices[i]] === true)
 q.rightchoice.push(choices[i])
 }
 q.setter = "Velayudhan Menon"
 q.used = false
 q.usedon = null
 q.insertedon = Date()
 const questions = [...allQuestions]
 questions[qIndex] = q;
 console.log(">>>> finished question")
 e.target.reset()
 setAllQuestions(questions)
 setQIndex(qIndex+1)
 /!*fetch('/api/addquestion', {
 method:"post",
 headers: {'Content-Type':'application/json', 'Accept':'application.json'},
 body: JSON.stringify(q)
 })
 .then(response => response.json())
 .then((json) => {
 if (json.id > 0)
 showInfo(`question successfully added. id is ${json.id}`)
 else
 showInfo('insertion of question failed.')
 })
 .catch(error => console.error(error));*!/
 }
 */
/* const closePopup = () => {
 setPopup({open:false, message:""})
 }*/

/*
{(qIndex === qBatchSize-1) &&
<>
<Button className="button-spacer" onClick={submitQuestions}>Done</Button>
<Button onClick={setPreview}>Preview</Button>
</>
}
{(qIndex < qBatchSize-1) && <Button onClick={goToNextQuestion}>Next Question</Button>}
    */