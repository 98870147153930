import React, { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import Button from 'react-bootstrap/Button';
import Modal from 'react-bootstrap/Modal';
import Form from 'react-bootstrap/Form';

import './App.css';

const SetQuestion = () => {

    const [questionId, setQuestionId] = useState(null)
    const [show, setShow] = useState({display:false, message:"", header:"", buttons:1})

    const navigate = useNavigate()

    const setQuestion = (e) => {
        e.preventDefault()
        fetch(`/api/setquestion/${questionId}`, {
            method:"put",
            headers: {'Content-Type':'application/json', 'Accept':'application.json'},
        })
            .then(response => response.json())
            .then((json) => {
                const sh = {display:true, header:"Information", buttons:1}
                if (json.id > 0)
                    sh.message = `question successfully set to ${json.id}`
                else
                    sh.message = `failed to set question ${questionId}`
                setShow(sh)
            })
            .catch(error => console.error(error));
    }

    const handleClose = (e) => {
        e && e.preventDefault()
        const sh = {display:false, message:"", header:"", buttons:1}
        setShow(sh)
    }

    return (
         <>
            <Form>
                <Form.Group className="mb-1">
                    <Form.Label>Question Id</Form.Label>
                    <Form.Control type="text"
                                  placeholder="ID of question to be set as today's question"
                                  onChange={ e => setQuestionId(e.target.value)}
                    />
                </Form.Group>
                <Button className="button-spacer" onClick={setQuestion}>Set Question</Button>
                <Button onClick={() => {navigate("/admin")}}>Cancel</Button>
            </Form>
            <Modal className="app-modal" show={show.display} onHide={handleClose}>
            <Modal.Header closeButton>
            <Modal.Title>{show.header}</Modal.Title>
            </Modal.Header>
            <Modal.Body>{show.message}</Modal.Body>
            <Modal.Footer>
            {
                (show.buttons === 1) &&
                <Button variant="secondary" onClick={handleClose}>OK</Button>
            }
            </Modal.Footer>
            </Modal>
        </>
    )
}


export default SetQuestion