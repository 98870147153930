import React, { useState, useContext, useEffect } from 'react';
import './App.css';
import Button from 'react-bootstrap/Button';
import {ProgressBar, Subject, Header, ColoredLine} from './common';

const isHoliday = (serverDate) => {
    const today = new Date(serverDate);
    const dow = today.getDay();
    if ((dow === 0) || (dow > 5))
        return true
    return false
}


export const  FirstPage = ({questions, page, setPage}) => {

    console.log("page>> ", page)
    const [ clicked, setClicked ] = useState(null)

    useEffect(() => {
        if (clicked === null) {
            const clickedArray = []
            for(let i=0; i<questions.length; i++) {
                clickedArray[i] = []
            }
            setClicked(clickedArray)
        }
    })

    const handleSubmit = () => {

        let score = 0
        const scoretable = {}
        scoretable.id = questions[0].id
        scoretable.selections = []
        scoretable.scores = []
        scoretable.totalscore = 0

        for (let i=0; i<questions.length; i++) {
            const q = questions[i]
            scoretable.selections[i] = clicked[i].slice()
            const correctanswers = clicked[i].reduce((acc, cl) => {
                if (q.rightchoice.includes(cl))
                    return acc+1
                else
                    return acc
            }, 0)
            console.log("submit: ", i, correctanswers)
            score = (correctanswers === q.rightchoice.length) && (clicked[i].length === correctanswers) ? 1 :
                ((correctanswers > 0) ?  0.5 : 0)
            console.log("score: ", score)
            scoretable.scores[i] = score
            scoretable.totalscore += score
            
        }

        fetch('/api/savescore', {
            method: "post",
            headers: {'Content-Type': 'application/json', 'Accept': 'application.json'},
            body: JSON.stringify({score:scoretable.totalscore, max:questions.length})
        })
            .catch(error => console.error(error));

        const newpage = {...page, page:2, scoretable}
        setPage(newpage)
    }

    if (clicked === null) {
        return null
    }

    const cloned = clicked.map((a) => a.slice())

    const holiday = false; //isHoliday(questions[0].serverDate)

    if (holiday) {
        return (
            <>
            <nav className="navbar navbar-expand-lg navbar-dark" aria-label="Main navigation">
                <Header/>
            </nav>
            <main className="container main-container">
                <div className="row">
                    <div className="col">
                        <div className="daily-quiz">
                            <ProgressBar questions={questions} />
                            <HolidayMessage/>
                        </div>
                    </div>
                </div>
            </main>
            </>
        )
    }

    return (
        <>
        <nav className="navbar navbar-expand-lg navbar-dark" aria-label="Main navigation">
            <Header/>
        </nav>
        <main className="container main-container">
            <div className="row">
                <div className="col">
                    <div className="daily-quiz">
                        <ProgressBar questions={questions} />
                        <Subject questions={questions}/>
                        <AllQuestions questions={questions} clicked={cloned} setClicked={setClicked}/>
                        <Button onClick={handleSubmit}>{page.page === 0 ? "Close Preview" : "Submit"}</Button>
                    </div>
                </div>
            </div>
        </main>
        </>
    )

}

const HolidayMessage = () => {
    return (
        <p className="text-center">No quiz on Saturdays and Sundays</p>
    )
}

const AllQuestions = ({questions, clicked, setClicked}) => {
    const qArray = []

    for (let i=0; i<questions.length; i++) {
        qArray.push(<Question key={i} q={questions[i]} index={i} clicked={clicked} setClicked={setClicked}/>)
    }

    return (
        qArray
    )
}

const Question = ({q, index, clicked, setClicked}) => {
    return (
        <div style={{height:"100%"}}>
            <p>
                <span style={{marginRight:"2rem"}}><b>{`Q${index+1}`}</b></span>
                <span style={{whiteSpace:"preserve"}}>{q.question}</span>
            </p>
            <Choices q={q} index={index} clicked={clicked} setClicked={setClicked}/>
            <ColoredLine color="black"/>
        </div>
    )
}

const Choices = ({q, index, clicked, setClicked}) => {

    const multichoice = (q.rightchoice.length > 1)

    const handleSelect = (e, index) => {
        const id = e.target.id

        if (multichoice) {
            const pos = clicked[index].indexOf(id)
            if (pos > -1)
                clicked[index].splice(pos,1)
            else
                clicked[index].push(id)
        }
        else {
            clicked[index].splice(0,1,id)
        }

        setClicked(clicked)
    }

    const isChecked = (key) => {
        if (clicked[index].includes(key))
            return true
        return false
    }

    const generateChoices = () => {
        const multichoice = (q.rightchoice.length > 1)
        const disableinput = false

        const choices = []
        for (let i=0; i<q.choices.length; i++) {
            const key = q.choices[i].label
            const name = multichoice ? `${index}${key}` : `${index}key`;
            const checked = isChecked(key)
            const id = `${key}`
            choices.push(
                <li key={key} className="d-flex mb-4">
                    <span className="v-buffer">{key}</span>
                    <input type="radio" 
                           checked={checked ? "checked": ""} 
                           name={name} 
                           onClick={(e) => {handleSelect(e,index)}}
                           onChange={(e) => {}}
                           id={id} 
                           disabled={disableinput} 
                           className="me-2 flex-shrink-0 custom-radio"/>
                    <label className="form-check-label" htmlFor="question">
                        {q.choices[i].value}
                    </label>
                </li>
            )
        }
        return choices
    }
    
    return (
        <div className="pt-5 v-maxwidth">
            <ul>
                {generateChoices(index)}
            </ul>
        </div>
    )
}

/*const GoToReview = () => {
    let { page, setPage } = useContext(PageContext);
    page = {...page}
    page.page = 2

    const handleClick = () => {
        setPage(page)
    }

    return (
        <div>
            <button type="button" onClick={handleClick}>See Feedback</button>
        </div>
    )
}*/



