import React, { useState, createContext, useContext } from 'react';
import { useNavigate } from 'react-router-dom';
import Button from 'react-bootstrap/Button';
import Modal from 'react-bootstrap/Modal';
import EditQuestion from './EditQuestion';

import './App.css';


const ShowQuestions = () => {

    const [questions, setQuestions] = useState(null)
    const navigate = useNavigate()
    const [deletedQuestions, setDeletedQuestions] = useState([])
    const [show, setShow] = useState({display:false, message:"", header:"", buttons:1})
    const [editQid, setEditQid] = useState("")

    if (questions === null) {
        fetch('/api/getallquestions')
            .then(response => response.json())
            .then(json => {setQuestions(json)})
            .catch(error => console.error(error));
    }

    const deleteQuestion = (_id) => {
        let dq = [...deletedQuestions]
        if (deletedQuestions.includes(_id))
            dq = dq.filter(i => i !== _id)
        else
            dq.push(_id)
        setDeletedQuestions(dq)
    }

    const showEditPage = (id) => {
        setEditQid(id)
    }

    const returnToShowQuestionsPage = (message) => {
        setEditQid("")
        if (message)
            setShow({display:true, header:"Information", buttons:1, message})
    }

    const genQuestionRows = (q) => {
        const rows = []
        for (let i = 0; i < q.length; i++) {
            const checked = deletedQuestions.includes(q[i]._id) ? true : false;
            rows.push(
                <tr key={q[i]._id}>
                    <th scope="col"><a style={{cursor:"pointer"}} onClick={()=>showEditPage(q[i]._id)}>{q[i].id}</a></th>
                    <td scope="col">{q[i].question}</td>
                    <th scope="col"><input type="checkbox"  checked={checked} onChange={()=>deleteQuestion(q[i]._id)}/></th>
                </tr>
            )
        }
        return rows
    }

    const handleClose = (e) => {
        e && e.preventDefault()
        const sh = {display:false, message:"", header:"", buttons:1}
        setShow(sh)
    }

    const confirmDelete = (e) => {
        e.preventDefault()
        const sh = {display:false, message:"", header:"", buttons:1}
        setShow(sh)
        fetch('/api/deletequestions', {
            method:"post",
            headers: {'Content-Type':'application/json', 'Accept':'application.json'},
            body: JSON.stringify(deletedQuestions)
        })
            .then(response => response.json())
            .then((json) => {
                const sh = {display:true, header:"Information", buttons:1}
                let remainder = [...questions]
                if (json.deleted) {
                    sh.message = `question(s) successfully deleted.`

                    remainder = remainder.filter((q) => {
                        return !(deletedQuestions.includes(q._id))
                    })
                    setShow(sh)
                    setDeletedQuestions([])
                    setQuestions(remainder)
                }
                else {
                    sh.message = `deletion of question(s) failed.`
                    setShow(sh)
                }


            })
            .catch(error => console.error(error));
    }

    const showConfirmDelete = (e) => {
        e.preventDefault()
        const sh = {
            display:true,
            message:`you are asking to delete ${deletedQuestions.length} question(s)`,
            header:`Are you sure?`,
            buttons:2
        }
        setShow(sh)
    }

    const DisplayQuestions = ({questions}) => {
        return (
            <>
            <div>
                <table className="table">
                    <thead>
                    <tr>
                        <th scope="col">Id</th>
                        <th scope="col">Question</th>
                        <th scope="col">Delete</th>
                    </tr>
                    </thead>
                    <tbody>
                    { genQuestionRows(questions) }
                    </tbody>
                </table>
                <Button className="button-spacer" onClick={() => {navigate("/admin")}}>Go Back</Button>
                {(deletedQuestions.length > 0) && <Button type="text" onClick={showConfirmDelete}>Delete Selected</Button>}
            </div>
            <Modal className="app-modal" show={show.display} onHide={handleClose}>
                <Modal.Header closeButton>
                    <Modal.Title>{show.header}</Modal.Title>
                </Modal.Header>
                <Modal.Body>{show.message}</Modal.Body>
                <Modal.Footer>
                    {
                        (show.buttons === 2) &&
                            <>
                            <Button variant="secondary" onClick={confirmDelete}>Confirm</Button>
                            <Button variant="secondary" onClick={handleClose}>Cancel</Button>
                            </>
                    }
                    {
                        (show.buttons === 1) &&
                        <Button variant="secondary" onClick={handleClose}>OK</Button>
                    }


                </Modal.Footer>
            </Modal>
            </>
        )
    }

    if (editQid !== "")
        return (<EditQuestion qid={editQid} getBack={returnToShowQuestionsPage}/>)
    else if (questions !== null)
        return (<DisplayQuestions questions={questions}/>)
    return null
    
}

export default ShowQuestions



