import React, { useState, createContext, useContext, useEffect } from 'react';
import './App.css';
import { WhatsappShareButton, WhatsappIcon } from 'react-share';
import Button from 'react-bootstrap/Button';
import {FirstPage} from './FirstPage'
import {ProgressBar, Subject, Header, ShareButton, ReadMore, ColoredLine} from './common';

export const SecondPage = ({questions, page, setPage}) => {

    return (
        <>
        <nav className="navbar navbar-expand-lg navbar-dark" aria-label="Main navigation">
            <Header/>
        </nav>
        <main className="container main-container">
            <div className="row">
            <div className="col">
                <div className="daily-quiz">
                    <ProgressBar questions={questions}/>
                    <Subject questions={questions} text="Feedback"/>
                    <Feedback questions={questions} scoretable={page.scoretable}/>
                    <div style={{textAlign:"right"}}>
                        <div style={{display:"inline-block", marginRight:"2%"}}>Share with friends</div>
                        <ShareButton/>
                    </div>
                </div>
            </div>
                </div>
        </main>
        </>
    )
}

const WrongIcon = () => {
    return (
        <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
            <circle cx="12" cy="12" r="10.5" fill="#FF1B1A" stroke="#FF1B1A" stroke-width="3"/>
            <path d="M5.12354 18.7098L9.85181 14.0968L18.877 5.28989" stroke="white" stroke-width="3"/>
            <path d="M18.877 18.7098L5.12354 5.28989" stroke="white" stroke-width="3"/>
        </svg>
    )
}

const Feedback = ({questions, scoretable}) => {

    const consolidatedFeedback = []
    for (let i = 0; i < questions.length; i++) {
        consolidatedFeedback.push(<p key={i}>{questions[i].feedback}</p>)
    }

    const feedback = []

    for (let i = 0; i < questions.length; i++) {

        const choices = []


        for (let j = 0; j < questions[i].choices.length; j++) {
            let checked = false
            let tickmark = false
            let crossmark = false
            if (scoretable.selections[i].includes(questions[i].choices[j].label))
                checked = true
            if (questions[i].rightchoice.includes(questions[i].choices[j].label))
                tickmark = true
            if ((!tickmark) && checked)
                crossmark = true
            console.log(">", i, j, checked, tickmark, crossmark)
            choices.push(
                <li key={questions[i].choices[j].label} className="d-flex mb-4">
                    <span className="v-buffer">{questions[i].choices[j].label}</span>
                    <input type="radio"
                           checked={checked ? "checked": ""}
                           disabled={true}
                           className="me-2 flex-shrink-0 custom-radio"/>
                    <label className="form-check-label" htmlFor="question" style={{color:tickmark?"green":(crossmark?"red":"default")}}>
                        {questions[i].choices[j].value}
                        {tickmark && (<img style={{marginLeft:"1rem"}} src="images/check-lg.svg"></img>)}
                        {crossmark && (<img style={{marginLeft:"1rem"}} src="images/x-lg.svg"></img>)}
                    </label>
                </li>
            )
        }

        //{tickmark && (<i className="bi bi-check fa-lg"></i>)}
        //{crossmark && (<i className="bi-exclamation-circle"></i>)}

        feedback.push(
            <div key={i}>
                <div>
                    <b style={{float:"left", marginRight: "2rem"}}>{`Q${i + 1}`}</b>
                    <ReadMore>{questions[i].question}</ReadMore>
                </div>
                <div>
                    <ul>
                        {choices}
                    </ul>
                </div>

            </div>
        )
    }
    return (
        <div className="correct-answer">
            <p>You scored {scoretable.totalscore} out of {questions.length}</p>
            <div className="col-md-12" style={{marginTop:"20px"}}>
                {feedback}
            </div>
            <ColoredLine color="black"/>
            <div>
                {consolidatedFeedback}
            </div>
        </div>
    )
}

//{(scoretable.scores[i] === 1) && (
//    <p>{`Your answer is correct`}<i style={{fontSize:"1rem"}} className="bi bi-check fa-lg"></i>
//    </p>)}
//{(scoretable.scores[i] === 0) && (<p>{`Your answer is wrong`}</p>)}
//{(scoretable.scores[i] === 0.5) && (<p>{`Your answer is partially correct`}</p>)}
/*
<div>
    <p>{`You selected choice(s) ${scoretable.selections[i].join()}`}</p>
</div>
<div>

</div>
<div>
{(scoretable.scores[i] !== 1) &&
(<p>{`The correct answer is ${questions[i].rightchoice.join()}`}</p>)}
</div>
*/

/*
const GoBack = () => {
    let { page, setPage } = useContext(PageContext);

    page = {...page}
    page.page = 1

    const handleClick = () => {
        setPage(page)
    }

    return (
        <div>
            <button type="button" onClick={handleClick}>See Question Again</button>
        </div>
    )
}*/

/*const GoToReview = () => {
    let { page, setPage } = useContext(PageContext);
    page = {...page}
    page.page = 2

    const handleClick = () => {
        setPage(page)
    }

    return (
        <div>
            <button type="button" onClick={handleClick}>See Feedback</button>
        </div>
    )
}*/


/*
const ShowResults = ({questions, scoretable}) => {



    const getAnswers = () => {
        const answers = []
        for (let i=0; i<q.rightchoice.length; i++) {
            const label = q.rightchoice[i]
            const rightchoice = q.choices.find((ch) => {
                return ch.label === label
            })
            answers.push(
                <li className="d-flex mb-4 true">
                    <img src="/images/true.svg" alt="" className="me-2 flex-shrink-0"/>
                    {rightchoice.value}
                </li>
            )
        }
        return answers
    }
    return (
        <>
        <div className="row">
            <div className="col-md-7">
                <h3>{header}</h3>
                <ul>
                    {getAnswers()}
                </ul>
            </div>
        </div>
        </>
    )
}



*/
