import React, { useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import './App.css';
import Button from 'react-bootstrap/Button';
import Form from 'react-bootstrap/Form';


const EditQuestion = ({qid, getBack}) => {
    const [question, setQuestion] = useState(null)
    const navigate = useNavigate()

    useEffect(() => {
        if (question === null) {
            fetch(`/api/getquestionby-id/${qid}`)
                .then(response => response.json())
                .then(json => setQuestion(json))
                .catch(error => console.error(error));
        }
    })

    const submitUpdateQuestion = (e) => {
        e.preventDefault()
        question.updatedon = Date()
        const _id = question._id
        fetch('/api/updatequestion', {
            method:"post",
            headers: {'Content-Type':'application/json', 'Accept':'application.json'},
            body: JSON.stringify(question)
        })
            .then(response => response.json())
            .then((json) => {
                if (json.id === _id)
                    getBack(`question has been successfully updated`)
                else
                    getBack(`question could not be updated`)
            })
            .catch(error => console.error(error));
    }

    const updateQuestion = (e, key) => {
        setQuestion({...question, [key]:e.target.value})
    }

    const updateChoice = (e, i) => {
        const ch = [...question.choices]
        ch[i].value = e.target.value
        setQuestion({...question, choices:ch})
    }

    const generateChoices = () => {
        const rows = []
        for (let i=0; i<question.choices.length; i++) {
            const isRight = question.rightchoice.includes(question.choices[i].label) ? 'Y' : 'N'
            rows.push (
                <tr key={i}>
                    <td scope="col">{question.choices[i].label}</td>
                    <td scope="col"><Form.Control type="text" value={question.choices[i].value} onChange={(e)=>updateChoice(e, i)}/></td>
                    <td scope="col">{isRight}</td>
                </tr>
            )
        }
        return rows
    }

    if (question === null)
        return null

    return (
        <div>
            <Form.Group className="mb-3">
                <Form.Label>Topic</Form.Label>
                <Form.Control type="text" value={question.topic || "General"} onChange={(e)=>updateQuestion(e, "topic")}/>
            </Form.Group>
            <Form.Group className="mb-3">
                <Form.Label>Question</Form.Label>
                <Form.Control as="textarea" rows={3} value={question.question} onChange={e => updateQuestion(e, "question")}/>
            </Form.Group>
            <Form.Group className="mb-3">
                <Form.Label>Choices</Form.Label>
                <table className="table">
                    <thead>
                    <tr>
                        <td scope="col">Label</td>
                        <td scope="col">Text</td>
                        <td scope="col">Is Correct</td>
                    </tr>
                    </thead>
                    <tbody>
                    { generateChoices() }
                    </tbody>
                </table>
            </Form.Group>
            <Form.Group className="mb-3">
                <Form.Label>Feedback</Form.Label>
                <Form.Control as="textarea" rows={3} value={question.feedback} onChange={e => updateQuestion(e, "feedback")}/>
            </Form.Group>

            <Button variant="secondary" onClick={e => submitUpdateQuestion(e)}>Submit Changes</Button>
        </div>

    )
}



export default EditQuestion


// <>
// <form>
// <label>Question</label>
// <textarea name="question" id="question" rows="5" cols="80"></textarea>
//     <div style={{height:"20px"}}></div>
// <h3>Answer Choices</h3>
// {genChoices()}
// <button onClick={addChoice}>Add Another Choice</button>
// <div style={{marginBottom:"20px"}}>
// <label style={{marginTop:"20px"}}>Feedback</label>
// <textarea name="feedback" id="feedback" rows="5" cols="80"></textarea>
//     </div>
//     <button type="submit" onClick={submitQuestion}>Submit Question</button>
// </form>
// </>
/*
<Form.Group className="mb-3">
    <Form.Label>Topic</Form.Label>
    <Form.Control type="text" value={question.topic || "General"} onChange={(e)=>updateQuestion(e, "topic")}/>
</Form.Group>*/
